export const Homepage = {
    id: 'homepage',
    path: '/',
}
export const Blog = {
    id: 'blog',
    path: '/blog',
}

export const Project = {
    id: 'project',
    path: '/project',
}

export const Contact = {
    id: 'contact',
    path: '/contact/',
}



const NavLinks = [Homepage, Blog, Project, Contact]

export default NavLinks
